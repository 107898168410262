import { piniaInstance } from "../js/global";
import { defineStore } from "pinia";
import { ref } from "vue";
import { postData } from "../js/util/fetchhandler";
import { doneMessage, errorMessage } from '../js/util/notifications';

export default defineStore("cart", () => {
  const items = ref([]);
  const totalItems = ref(0);
  const totalCost = ref(0);

  const getItems = async () => {
    let response = await services.shoppingcart.get();
    items.value = Object.values(response);
    totalItems.value = items.value.length;
    totalCost.value = items.value.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.price * currentValue.qty;
    }, 0);
  };

  const addItem = async (data) => {
    let response = await services.shoppingcart.add(data);
    if (response.status) {
      doneMessage("Producto agregado al pedido");
      getItems();
    } else {
      errorMessage("Ha ocurrrido un error. Reintente por favor");
    }
  };

  const updateItem = async (rowid, data) => {
    let response = await services.shoppingcart.update(rowid, data);
    if (response.status) {
      doneMessage("Pedido actualizado");
      getItems();
    } else {
      errorMessage("Ha ocurrrido un error. Reintente por favor");
    }
  };

  const removeItem = async (rowid) => {
    let response = await services.shoppingcart.remove(rowid);
    if (response.status) {
      doneMessage("Producto eliminado del pedido");
      getItems();
    } else {
      errorMessage("Ha ocurrrido un error. Reintente por favor");
    }
  };

  const clear = async () => {
    let response = await services.shoppingcart.clear();
    if (response.status) {
      // doneMessage("Pedido cancelado correctamente");
      getItems();
    } else {
      errorMessage("Ha ocurrrido un error. Reintente por favor");
    }
  };

  const createOrder = async (
    freeShipping = false,
    shippingMethod = "DELIVERY",
    chippingProvinceId = "",
    shippingCityId = "",
    shippingAddress = "",
    shippingZipCode = ""
  ) => {
    try {
      let dataOrder = {
        free_shipping: freeShipping,
        shipping_method: shippingMethod,
        province_id: chippingProvinceId,
        city_id: shippingCityId,
        address: shippingAddress,
        zip_code: shippingZipCode,
      };

      let result = await postData(
        `/api/orders/create`,
        dataOrder
      ).then((data) => {
        return data;
      });

      return result;
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  getItems();

  return {
    items,
    totalItems,
    totalCost,
    getItems,
    addItem,
    removeItem,
    updateItem,
    clear,
    createOrder,
  };
})(piniaInstance);
